<template>
  <div
    if="schema.ctrls.id.value !== ''"
    class="exp_M_res conflex"
    style="justify-content: center; background-color: #eee;padding:20px">
    
    <div style="flex: 1 1 ;max-width:600px">

        <div class="cab">FIN COMPRAS</div>
        

        <v-sheet v-bind="$cfg.styles.marco"  >
          <div class="conflex">
            <!-- <v-checkbox
              v-bind="$checkbox"
              v-model="schema.ctrls.chkpresapd.value"
              :label="schema.ctrls.chkpresapd.label"
              :disabled="!is_edit || app == 1"
              @change="finComprasApd"
            >
            </v-checkbox> -->
            <v-btn rounded 
              icon 
              @click="finComprasApd"              
              :disabled="!is_edit || (app==1 && ndep<3)"> 
                <v-icon>{{ schema.ctrls.chkpresapd.value== "0"? 'mdi-checkbox-blank-outline' : 'mdi-checkbox-outline'}}</v-icon>
            </v-btn>

            <!-- stilot="background-color:rgb(100, 140, 140,.2);text-align:center" -->
            <vllabel
              style="width:30%"            
              stilot="text-align:center"
              label=""  
              :txt="schema.ctrls.chkpresapddate.value | fechahr">              
            </vllabel>  
            <vllabel              
              style="width:30%"                          
              label=""  
              :txt="schema.ctrls.apdfin.value">              
            </vllabel>              

          </div>
          
            <v-checkbox
              v-bind="$checkbox"
              v-model="schema.ctrls.cs_aplica.value"
              label="CS Aplicado"
              :disabled="!is_edit || app == 1"
            >
            </v-checkbox>

            <v-checkbox
              v-bind="$checkbox"
              v-model="schema.ctrls.cs_aplicajz.value"
              label="VB COS"
              :disabled="!is_edit || rol != 'jz'">            
            </v-checkbox>
        </v-sheet>
  

  
      
      <div class="conflex" style="width:100%">

       <div class="columna" style="width:50%">
          <div class="cab">RESUMEN</div>
        <v-sheet v-bind="$cfg.styles.marco" >
                <vllabel
                  stilot="background-color:rgb(70, 131, 180,.3);text-align:right"
                  stilol="background-color:rgb(70, 131, 180,.3)"
                  label="CAPITAL"
                  :txt="capital | num(2)"
                >
                </vllabel>

                <vllabel
                  stilot="background-color:rgb(214, 234, 248 ,.8);text-align:right"
                  stilol="background-color:rgb(214, 234, 248 ,.8)"
                  label="COMPRAS"
                  :txt="compras | num(2)"
                >
                </vllabel>

                <vllabel
                  stilot="background-color:rgb(214, 234, 248 ,.8);text-align:right"
                  stilol="background-color:rgb(214, 234, 248 ,.8)"
                  label="DIFER."
                  :txt="diferencia | num(2)"
                >
                </vllabel>
              </v-sheet>
            </div>
       
          <!-- ------- -->

          <!-- ------------------- -->
          <div class="columna" style="width:50%">
            <div class="cab">CAUSAS MODIFICACIÓN PRESTO / APLICACIÓN CS</div>
            <v-sheet v-bind="$cfg.styles.marco"  >
              <v-checkbox
                v-bind="$checkbox"
                v-model="schema.ctrls.dif1.value"
                label="Alteración Precio"
                :disabled="!is_edit || app == 1"
              >
              </v-checkbox>

              <v-checkbox
                v-bind="$checkbox"
                v-model="schema.ctrls.dif3.value"
                label="Sustitución"
                :disabled="!is_edit || app == 1"
              >
              </v-checkbox>

              <v-checkbox
                v-bind="$checkbox"
                v-model="schema.ctrls.dif2.value"
                label="Exigencia Familia"
                :disabled="!is_edit || app == 1"
              >
              </v-checkbox>

              <v-checkbox
                v-bind="$checkbox"
                v-model="schema.ctrls.dif4.value"
                label="Otros"
                :disabled="!is_edit || app == 1"
              >
              </v-checkbox>
            </v-sheet>
          </div>
         </div>
          <!-- -------------- -->
     
        <!-- ========================== -->

        <ctrlobs3
          :schemaComponente="schema.ctrls.difarg"
          :schema="schema"
          :record="record"
          :edicion="is_edit && app == 2"
        >
        </ctrlobs3>
     
       </div>
  </div>

  <!-- </div> -->
</template>



<script>
import { mixinCtrls } from "@/mixins/mixinCtrls";
import plugs from "@/common/general_plugs";
const ctrlobs3 = () => plugs.groute("ctrlobs3.vue", "comp");

export default {
  mixins: [mixinCtrls],
  components: { ctrlobs3 },
  props: {
    padre: { type: String, default: "" },
    componenteTipo: { type: String, default: "M" },
    disparoTab: { type: Boolean, default: false },
    Entorno: {
      type: Object,
      default: function () {
        return JSON.parse(JSON.stringify(this.$cfg.base.M));
      },
    },
  },

  data() {
    return {
      schema: null,
      capital: 0,
      compras: 0,
      diferencia: 0,
    };
  },

  created() {
    this.ini_data();
  },

  computed: {
    recordPres() {
      return this.$store.state.stMexp.recordsDetalle[2];
    },

    record: function () {
      return this.$store.state.stMexp.record;
    },

    // devuelvo si estamos en modo edición en el Mto
    is_edit() {
      return this.$store.state.stMexp.estado === "editar" ||
        this.$store.state.stMexp.estado === "nuevo"
        ? true
        : false;
    },
  },

  methods: {
    ini_data() {
      console.log(" dev ********************** exp_M_res ini_data");
      // guardo del store en variables locales
      this.schema = this.$store.state.stMexp.schema;
    },

    // checkbox de Fin Compras
    finComprasApd() {
      // si ya está seleccionado, deshacer fin compras
      if (this.schema.ctrls.chkpresapd.value == "1") {
        this.anular_fin_compras();        
        return;
      }

      // recorro array presupuestos para comprobar si estan todas aceptadas o rechazadas
      let OK=false;
      this.recordPres.forEach((pres) => {
        if (pres.aceptada == 0) {
          this.schema.ctrls.chkpresapd.value = "0";
          this.$root.$alert.open(
            "Fin de compras NO permitido (Existen presupuestos sin aceptar o rechazar)",
            "error"
          );          
          OK=true;
          return;
        }
      });
      if (OK) {
        this.anular_fin_compras();
        return;
      } 
      
      // actualizo la fecha (actual) y el usuario
      this.schema.ctrls.chkpresapddate.value = this.actualDateTime();
      this.schema.ctrls.apdfin.value = this.usu;
      this.schema.ctrls.chkpresapd.value = "1"
      
    },

    // retroceder a NO Fin compras APD
    anular_fin_compras() {
      this.schema.ctrls.chkpresapddate.value = "";
      this.schema.ctrls.apdfin.value = "";
      this.schema.ctrls.chkpresapd.value = "0";
      
      //this.schema.ctrls.chkpresapddate.value = "";
      //this.schema.ctrls.apdfin.value = "";
      //presup.apd_cerrojo()
      //return;
    },

    calcular() {
      // recorro array presupuestos para calcular suma de importes
      this.compras = 0;
      this.recordPres.forEach((pres) => {
        if (pres.bloqueada == 1 && pres.aceptada == 1 && pres.tipo_ope == "C")
          this.compras += Number(pres.imp);
      });

      // actualizo cantidades
      this.capital =
        Number(this.record.servpm) +
        Number(this.record.servnicho) +
        Number(this.record.servincinera) +
        Number(this.record.servlapida) +
        Number(this.record.servtana) +
        Number(this.record.servcomplemento);
      this.diferencia = this.capital - this.compras;
      if (this.compras == 0) this.compras = "S/COMPRAS";
    },
  },

  watch: {
    disparoTab: {
      immediate: true,
      handler() {
        this.calcular("calculoTab");
      },
    },

    "record.id": {
      deep: true,
      handler() {
        this.calcular();
      },
    },
  },
};
</script>
